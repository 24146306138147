import { css } from '@emotion/core'
import React from 'react'
import Icon from '../common/Icon'
import Spreader from '../common/Spreader'
import Link from 'next/link'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX

import {
  TABLET_OR_MORE,
  GRAY_MEDIUM,
  WHITE,
  DESKTOP_OR_MORE,
  BLACK,
  MOBILE
} from '../../constants/theme.styles'

import { getCategoryRoute } from '../domain/blog/blogUtils'
import links, { browsePage, browseUKPage, postJobUrl, superPostUrl } from '../../lib/links'
import Button from '../common/Button'
import { HighlightText } from '../domain/home/HighlightText'
import { useRouter } from 'next/router'
import Container from '../common/Container'
import { TextBreak } from '../domain/home/TextBreak'
import { useFlags } from '~/context/FlagsContext'
import { useLangActions, useLang } from '~/context/LangContext'
import { useCookies } from '~/context/CookieContext'
import { Mixpanel } from '~/lib/mixpanel'
import { useNoticeActions } from '~/context/NoticeContext'
import { SuccessNotice } from '../common/Notice/StyledNotices'

const langButtonStyles = css`
  font-size: 14px;
  padding: 9px 12px;
  @media ${TABLET_OR_MORE} {
    padding: 6px 9px;
  }
  :active,
  :focus {
    border: 1px solid ${GRAY_MEDIUM};
    background: ${BLACK};
  }
`
const activeLangStyles = css`
  border: 1px solid ${GRAY_MEDIUM};
  :hover {
    border: 1px solid ${WHITE};
    background: ${BLACK};
  }
`

const listStyles = css`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    font-size: 14px;
    line-height: 32px;

    @media ${TABLET_OR_MORE} {
      font-size: 16px;
    }

    a {
      color: #f6f7fa;
      text-decoration: none;

      &:hover {
        color: #fff;
        text-decoration: underline;
        transition: all 300ms ease;
      }
    }
  }
`

const listHeadingStyles = css`
  font-weight: 900;
  color: #f6f7fa;
  font-size: 18px;
  line-height: 23px;
  margin: 0 0 25px 0;

  @media ${TABLET_OR_MORE} {
    margin: 0 0 50px 0;
    font-size: 30px;
    line-height: 23px;
    :after {
      bottom: -0.25em;
      height: 0.15em;
    }
  }
`

const iconRowStyles = css`
  margin-left: -18px;
  a {
    margin-left: 18px;
    margin-bottom: 18px;
  }
`

// CLEARBIT
const clearBitStyles = css`
  margin-top: 15px;
  a {
    color: ${GRAY_MEDIUM};
    text-decoration: none;

    &:hover {
      transition: all 1s ease;
      color: ${WHITE};
      text-decoration: none;
    }
  }
`

const TwitterIcon = () => (
  <Icon
    element="a"
    rel="noopener"
    href={links.twitter()}
    target="__blank"
    size="huge"
    color="dark"
    icon="twitter"
    circular
    css={css`
      background: #2f2f2f;
    `}
  />
)

const FacebookIcon = () => (
  <Icon
    element="a"
    rel="noopener"
    href={links.facebook()}
    target="_blank"
    size="huge"
    color="dark"
    icon="facebook"
    circular
    css={css`
      background: #2f2f2f;
    `}
  />
)

const InstagramIcon = () => (
  <Icon
    element="a"
    rel="noopener"
    href={links.instagram()}
    target="_blank"
    size="huge"
    color="dark"
    icon="instagram"
    circular
    css={css`
      background: #2f2f2f;
    `}
  />
)

const LinkedinIcon = () => (
  <Icon
    element="a"
    rel="noopener"
    href={links.linkedin()}
    target="_blank"
    size="huge"
    color="dark"
    icon="linkedin"
    circular
    css={css`
      background: #2f2f2f;
    `}
  />
)

interface Props {
  showClearbit?: boolean
  showGetStarted?: boolean
  showJobPostingStarted?: boolean
  onGetStarted?: () => void
  hasSuperPostFeature?: boolean
}

const FooterLanguageToggles = () => {
  const { setLanguage } = useLangActions()
  const { lang } = useLang()
  const { createNotice } = useNoticeActions()
  return (
    <div>
      <h2
        css={css`
          color: ${GRAY_MEDIUM};
          margin-bottom: 9px;
          font-size: 14px;
          margin-top: 5px;
          font-weight: normal;
        `}
      >
        Language
      </h2>
      <Button
        css={[langButtonStyles, lang === 'en' ? activeLangStyles : null]}
        onClick={() => {
          Mixpanel.track('Language - Footer - English', {})
          setLanguage('en')
          createNotice(<SuccessNotice>English selected!</SuccessNotice>, {
            position: 'bottomRight',
            color: 'light',
            timeout: 6000,
            showSoloNotice: true
          })
        }}
      >
        en
      </Button>
      <Button
        css={[
          langButtonStyles,
          lang === 'fr' ? activeLangStyles : null,
          css`
            margin-left: 4px;
          `
        ]}
        onClick={() => {
          Mixpanel.track('Language - Footer - French', {})
          setLanguage('fr')
          createNotice(<SuccessNotice>Française sélectionnée!</SuccessNotice>, {
            position: 'bottomRight',
            color: 'light',
            timeout: 6000,
            showSoloNotice: true
          })
        }}
      >
        fr
      </Button>
      <Button
        css={[
          langButtonStyles,
          lang === 'es' ? activeLangStyles : null,
          css`
            margin-left: 4px;
          `
        ]}
        onClick={() => {
          Mixpanel.track('Language - Footer - Spanish', {})
          setLanguage('es')
          createNotice(<SuccessNotice>Español seleccionado!</SuccessNotice>, {
            position: 'bottomRight',
            color: 'light',
            timeout: 6000,
            showSoloNotice: true
          })
        }}
      >
        es
      </Button>
    </div>
  )
}

const Footer = ({
  showClearbit,
  showGetStarted,
  showJobPostingStarted,
  hasSuperPostFeature = false,
  onGetStarted
}: Props) => {
  const router = useRouter()
  const flags = useFlags()
  const { siteLang } = useCookies()
  const showLangToggle = siteLang || router.query.alg
  const onPostAJob = router.pathname === '/post-a-job'
  return (
    <footer
      data-cy="footer-container"
      css={css`
        background: #222;
        width: 100%;
        display: ${onPostAJob === true ? 'none' : 'block'};
      `}
    >
      <Container
        css={[
          css`
            padding-top: 70px;
            display: none;
            @media ${TABLET_OR_MORE} {
              display: ${showGetStarted === false && showJobPostingStarted === false
                ? 'none'
                : 'block'};
            }
            @media ${MOBILE} {
              padding-top: 36px;
              display: ${showJobPostingStarted === true ? 'block' : 'none'};
            }
          `
        ]}
      >
        <div
          css={css`
            text-align: center;
            @media ${TABLET_OR_MORE} {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          `}
        >
          <h2
            css={css`
              font-size: 28px;
              color: #f6f7fa;
              font-weight: 600;
              text-align: center;
              @media ${TABLET_OR_MORE} {
                text-align: left;
                font-size: 50px;
              }
              @media ${DESKTOP_OR_MORE} {
                text-align: left;
                font-size: 60px;
              }
            `}
          >
            {showJobPostingStarted ? (
              <>
                <div
                  css={css`
                    display: none;
                    @media ${TABLET_OR_MORE} {
                      display: ${showJobPostingStarted === true ? 'block' : 'none'};
                    }
                  `}
                >
                  Ready to{' '}
                  <TextBreak>
                    <HighlightText>start hiring?</HighlightText>
                  </TextBreak>
                </div>
                <div
                  css={css`
                    display: none;
                    @media ${MOBILE} {
                      display: ${showJobPostingStarted === true ? 'block' : 'none'};
                    }
                  `}
                >
                  Ready to <HighlightText>start hiring?</HighlightText>
                </div>
              </>
            ) : (
              <>
                Ready to build{' '}
                <TextBreak>
                  <HighlightText>your Joblist?</HighlightText>
                </TextBreak>
              </>
            )}
          </h2>
          <div
            css={css`
              display: flex;
              @media ${TABLET_OR_MORE} {
                flex-direction: column;
                align-items: center;
              }
              @media ${DESKTOP_OR_MORE} {
                flex-direction: row;
              }
            `}
          >
            <Button
              oversize
              extraCss={css`
                background: #7e5bd8;
                font-size: 14px;
                margin-top: 42px;
                width: 190px;
                @media ${TABLET_OR_MORE} {
                  margin-top: 0;
                }
                @media ${DESKTOP_OR_MORE} {
                  margin-top: 0;
                  font-size: 17px;
                  width: 210px;
                }
                @media ${MOBILE} {
                  margin: 0 auto;
                  margin-top: 36px;
                  width: 80%;
                  font-size: 16px;
                }

                &:hover,
                &:active,
                &:focus {
                  background: #956bfd;
                }

                g {
                  fill: #fff;
                }
              `}
              onClick={() => {
                if (showJobPostingStarted) {
                  router.push(hasSuperPostFeature ? superPostUrl : postJobUrl)
                } else {
                  if (onGetStarted) {
                    onGetStarted()
                  } else {
                    router.push('/quiz')
                  }
                }
              }}
              size="large"
              arrow
            >
              {showJobPostingStarted ? 'Post a Job' : 'Get Started'}
            </Button>
          </div>
        </div>

        <div
          css={css`
            width: 100%;
            border-bottom: 1px solid #515151;
            padding-top: 70px;
            @media ${MOBILE} {
              padding-top: 48px;
            }
          `}
        />
      </Container>
      <Container
        css={[
          css`
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 50px;
            padding-right: 50px;
            @media (min-width: 376px) and (max-width: 767px) {
              padding-left: 30px;
              padding-right: 30px;
            }

            @media ${TABLET_OR_MORE} {
              padding-bottom: 100px;
            }
          `
        ]}
      >
        <Spreader distribution="fill" spacing="relaxed">
          <Spreader.Item>
            <HighlightText color="yellow" css={listHeadingStyles}>
              Company
            </HighlightText>
            <ul css={listStyles}>
              <li>
                <Link prefetch={false} href={links.home()} passHref>
                  <a>Home</a>
                </Link>
              </li>
              <li>
                <Link prefetch={false} href={links.about()} passHref>
                  <a>About</a>
                </Link>
              </li>
              <li>
                <Link
                  prefetch={false}
                  href={{
                    pathname: '/category',
                    query: { categorySlug: 'all' }
                  }}
                  as={getCategoryRoute('all')}
                  passHref
                >
                  <a>Blog</a>
                </Link>
              </li>
              {flags.showPressPage ? (
                <li>
                  <Link prefetch={false} href={links.press()} passHref>
                    <a>Press</a>
                  </Link>
                </li>
              ) : null}
              <li>
                <a href={browsePage()}>Browse US Jobs</a>
              </li>
              <li>
                <a href={browseUKPage()}>Browse UK Jobs</a>
              </li>
              {/* <li>
                <a href={intlBrowsePage()}>UK Jobs</a>
              </li> */}
              {flags.showPostJobLink ? (
                <li>
                  <Link
                    prefetch={false}
                    href={hasSuperPostFeature ? superPostUrl : postJobUrl}
                    passHref
                  >
                    <a>Post a Job</a>
                  </Link>
                </li>
              ) : null}
              {flags.showWorkAtJoblistLink ? (
                <li>
                  <Link prefetch={false} href={links.workAtJoblist()} passHref>
                    <a>Work at Joblist</a>
                  </Link>
                </li>
              ) : null}
            </ul>
          </Spreader.Item>
          <Spreader.Item>
            <HighlightText color="blue" css={listHeadingStyles}>
              Support
            </HighlightText>
            <ul css={listStyles}>
              <li>
                <Link prefetch={false} href={links.faq()} passHref>
                  <a>FAQ</a>
                </Link>
              </li>
              <li>
                <Link prefetch={false} href={links.contact()} passHref>
                  <a>Contact</a>
                </Link>
              </li>
              <li>
                <Link prefetch={false} href={links.terms()} passHref>
                  <a>Terms</a>
                </Link>
              </li>
              <li>
                <Link prefetch={false} href={links.privacy()} passHref>
                  <a>Privacy</a>
                </Link>
              </li>
            </ul>
          </Spreader.Item>
          <Spreader.Item
            css={[
              css`
                @media (max-width: 376px) {
                  display: none;
                }
              `
            ]}
          >
            <HighlightText css={listHeadingStyles}>Social</HighlightText>
            <div css={iconRowStyles}>
              <TwitterIcon />
              <FacebookIcon />
            </div>
            <div css={iconRowStyles}>
              <InstagramIcon />
              <LinkedinIcon />
            </div>

            {showLangToggle ? (
              <div
                css={css`
                  display: none;
                  @media ${TABLET_OR_MORE} {
                    display: block;
                    margin-top: 10px;
                  }
                `}
              >
                {' '}
                <FooterLanguageToggles />
              </div>
            ) : null}

            {showClearbit && (
              <div css={clearBitStyles}>
                <a rel="noopener" href={links.clearbit()} target="_blank">
                  Logos provided by Clearbit
                </a>
              </div>
            )}
          </Spreader.Item>
        </Spreader>
      </Container>
      <Container
        css={[
          css`
            padding-bottom: 65px;
            padding-left: 50px;
            padding-right: 50px;
            @media (min-width: 376px) {
              display: none;
            }
          `
        ]}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <TwitterIcon />
          <FacebookIcon />
          <InstagramIcon />
          <LinkedinIcon />
        </div>
      </Container>
      <Container
        css={[
          css`
            padding-bottom: 40px;
            @media ${TABLET_OR_MORE} {
              padding-bottom: 40px;
            }
          `
        ]}
      >
        <Spreader distribution="spaceBetween" spacing="relaxed">
          <Spreader.Item
            css={css`
              display: none;
              @media ${TABLET_OR_MORE} {
                display: block;
              }
            `}
          >
            <a href={links.home()}>
              <img
                src={`${PUBLIC_ASSET_PREFIX}/static/icons/Joblist_Logo_White.svg`}
                css={css`
                  width: 105px;
                `}
                alt="Joblist"
              />
            </a>
          </Spreader.Item>
          <Spreader.Item
            css={css`
              width: 100%;
              @media ${TABLET_OR_MORE} {
                width: auto;
              }
            `}
          >
            {showLangToggle ? (
              <div
                css={css`
                  text-align: center;
                  margin-bottom: 30px;
                  @media ${TABLET_OR_MORE} {
                    display: none;
                  }
                `}
              >
                <FooterLanguageToggles />
              </div>
            ) : null}

            <div
              css={css`
                color: #f6f7fa;
                text-decoration: none;
                font-size: 14px;
                margin-bottom: 5px;
                margin-top: 5px;
                text-align: center;
                opacity: 0.6;

                @media ${TABLET_OR_MORE} {
                  font-size: 16px;
                  opacity: 1;
                }
                a {
                  color: #f6f7fa;
                  font-weight: 600;
                  &:hover {
                    color: ${WHITE};
                    text-decoration: underline;
                  }
                }

                @media ${TABLET_OR_MORE} {
                  display: block;
                  text-align: left;
                }
              `}
            >
              © {new Date().getFullYear()} Joblist, a{' '}
              <a href={links.wilburLabs()} target="_blank" rel="noopener">
                Wilbur Labs
              </a>{' '}
              company.
            </div>
          </Spreader.Item>
        </Spreader>
      </Container>
    </footer>
  )
}

export default Footer
