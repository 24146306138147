import urlJoin from 'url-join'
import { SupportedCategoryColors } from './CategoryLink'

export const categoryColors: any = {
  all: {
    color: null
  },
  guides: {
    color: 'blue'
  },
  trends: {
    color: 'purple'
  },
  ['jobs reports']: {
    color: 'orange'
  },
  news: {
    color: 'yellow'
  }
}

export const categoryRoutes: any = {
  all: '/career-advice',
  guides: '/guides',
  trends: '/trends',
  ['jobs-reports']: '/jobs-reports',
  news: '/news'
}

export const getCategoryColor = (category: string | null | undefined): SupportedCategoryColors => {
  if (!category) {
    return null
  }
  const lowerCaseCat = category.toLowerCase()
  const color = categoryColors[lowerCaseCat] ? categoryColors[lowerCaseCat].color : 'dark'
  return color
}

export const getCategoryRoute = (category: string): string => {
  // const lowerCaseCat = category.toLowerCase()
  const catWithDashes = category
    .toLowerCase()
    .split(' ')
    .join('-')
  return categoryRoutes[catWithDashes] ? categoryRoutes[catWithDashes] : null
}

export const constructArticleUri = (articleUriData: {
  uri: string
  categories: Array<{ uri?: string | null } | null> | undefined | null
}) => {
  const { uri, categories } = articleUriData
  const categoryUri = Array.isArray(categories) && categories.length > 0 ? `/${categories[0]?.uri}` : '/career-advice'
  return urlJoin(categoryUri, uri)
}

export function getEntries(categoryName: string | undefined, data: any): any[] {
  return (
    (categoryName === 'all'
      ? data?.entries
      : data?.categoriesConnection?.edges[0]?.relatedEntries?.entries) || []
  )
}
